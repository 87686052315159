import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';
import { Socket } from 'ngx-socket-io';
import { Subscription } from 'rxjs';
import { EReceiveChatEventName } from '../enums/chat.enum';
import { IErrorWebsocket } from '../models/error/error';
import { AuthenticationService } from '../services/authentication/authentication.service';

@Injectable()
export class ChatSocket extends Socket implements OnDestroy {
    private readonly subscriptions = new Subscription();
    readonly onDisconnect = new EventEmitter<void>();
    readonly onConnectError = new EventEmitter<void>();
    readonly onException = new EventEmitter<IErrorWebsocket>();

    constructor(private readonly translateService: TranslateService, private readonly authenticationService: AuthenticationService) {
        super({
            url: `${environment.apiUrl}/chat`,
            options: {
                reconnection: false,
                reconnectionAttempts: 0,
                transports: ['websocket'],
            },
        });

        this.disconnect();

        this.on('connect_error', () => {
            this.onConnectError.next(null);
        });

        this.on('disconnect', () => {
            this.onDisconnect.next(null);
        });

        this.on(EReceiveChatEventName.EXCEPTION, (data: IErrorWebsocket) => {
            this.onException.next(data);
        });
    }

    connect(): any {
        this.ioSocket.disconnect();
        this.ioSocket.io.opts.headers = {
            Authorization: `Bearer ${this.authenticationService.token}`,
        };
        this.ioSocket.io.opts.query = {
            token: this.authenticationService.token,
            clinic: this.authenticationService.clinicId(),
            lang: this.translateService.currentLang === 'pt' ? 'pt-br' : this.translateService.currentLang,
        };
        this.ioSocket.connect();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
