export enum EReceiveChatEventName {
    MESSAGE_ORDER = 'message_order',
    EXCEPTION = 'exception',
}

export enum ESendChatEventName {
    LEAVE_ROOM = 'leave_room',
    JOIN_ROOM_LAB = 'join_room_lab',
    JOIN_ROOM_CLINIC = 'join_room_clinic',
    MESSAGE_ORDER_LAB = 'message_order_lab',
    MESSAGE_ORDER_CLINIC = 'message_order_clinic',
}
