import { Directive, Input, OnChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { IKiwidPermission } from '../model/kiwid-permissions';
import { KiwidPermissionsService } from '../service/kiwid-permissions.service';

@Directive({
    selector: '[kiwidPermissions]',
})
export class KiwidPermissionsDirective implements OnChanges {
    @Input() kiwidPermissions: IKiwidPermission = {
        routeAccess: [],
        routeOperator: 'or',
    };

    constructor(
        private readonly kiwidPermissionsService: KiwidPermissionsService,
        private viewContainerRef: ViewContainerRef,
        private templateRef: TemplateRef<any>,
    ) {
    }

    ngOnChanges(): void {
        if (this.kiwidPermissionsService.hasPermission(this.kiwidPermissions)) {
            this.viewContainerRef.createEmbeddedView(this.templateRef);
            return;
        }

        this.viewContainerRef.clear();
    }
}
