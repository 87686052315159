import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { EventEmitter, Injectable, OnDestroy, inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { EReceiveChatEventName, ESendChatEventName } from '../../enums/chat.enum';
import { IDataPagination } from '../../models/api/data';
import { IChat, IChatBadge, IGetAllChat, IGetAllChatBadge, IJoinRoom, ISendOrderChat } from '../../models/chat/chat';
import { IError, IErrorWebsocket } from '../../models/error/error';
import { ChatSocket } from '../../socket/chat-socket';
import { AuthenticationService } from '../authentication/authentication.service';

@Injectable({
    providedIn: 'root',
})
export class ChatService implements OnDestroy {
    private readonly router = inject(Router);
    private readonly httpClient = inject(HttpClient);
    private readonly translateService = inject(TranslateService);
    private readonly authenticationService = inject(AuthenticationService);

    private roomId: string;
    private _isConnectedRoom = false;
    private socket: ChatSocket | null = null;

    ngOnDestroy(): void {
        this.leaveRoom(this.roomId);
        this.socket.disconnect();
    }

    disconnect(): void {
        this.socket.disconnect();
    }

    get isConnectedRoom(): boolean {
        return this._isConnectedRoom;
    }

    get onException(): EventEmitter<IErrorWebsocket> {
        return this.socket?.onException;
    }

    get onDisconnect(): EventEmitter<void> {
        return this.socket?.onDisconnect;
    }

    get onConnectError(): EventEmitter<void> {
        return this.socket?.onConnectError;
    }

    joinRoom(parameters: IJoinRoom): Observable<string> {
        this.connect();

        return new Observable<string>((observable) => {
            this.socket.emit(ESendChatEventName.JOIN_ROOM_CLINIC, parameters, (roomId: string) => {
                this.roomId = roomId;
                observable.next(roomId);
                this._isConnectedRoom = true;
                observable.complete();
            });
        });
    }

    leaveRoom(roomId: string): void {
        this.roomId = null;
        this._isConnectedRoom = false;
        this.socket.emit(ESendChatEventName.LEAVE_ROOM, roomId);
    }

    sendMessage(message: ISendOrderChat, roomId = this.roomId): void {
        this.socket.emit(ESendChatEventName.MESSAGE_ORDER_CLINIC, { ...message, roomId });
    }

    getMessage(): Observable<IChat> {
        return this.socket.fromEvent(EReceiveChatEventName.MESSAGE_ORDER).pipe(map((data) => data as IChat));
    }

    getAllChat(parameters: IGetAllChat): Observable<IDataPagination<IChat[]>> {
        const baseUrl = ['clinic', 'admin', 'chat'];

        const url = this.router.createUrlTree(baseUrl, {
            queryParams: parameters,
        });

        const parsedUrl = `${environment.apiUrl}/${environment.apiVersion}${url}`;

        return this.httpClient.get(parsedUrl, { responseType: 'json' }).pipe(
            map((res: IDataPagination<IChat[]>) => {
                return res;
            }),
            catchError((error: HttpErrorResponse) => {
                if (error.error instanceof ErrorEvent) {
                    return throwError(() => `Error: ${error.error.message}`);
                }
                const errorApi: IError = error.error.error;
                return throwError(() => errorApi);
            }),
        );
    }

    getAllChatBadges(data: IGetAllChatBadge): Observable<IDataPagination<IChatBadge[]>> {
        const baseUrl = ['clinic', 'admin', 'chat', 'badge'];

        const url = this.router.createUrlTree(baseUrl, {
            queryParams: data,
        });

        const parsedUrl = `${environment.apiUrl}/${environment.apiVersion}${url}`;

        return this.httpClient.get<IDataPagination<IChatBadge[]>>(parsedUrl, { responseType: 'json' }).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.error instanceof ErrorEvent) {
                    return throwError(() => `Error: ${error.error.message}`);
                }
                const errorApi: IError = error.error.error;
                return throwError(() => errorApi);
            }),
        );
    }

    clearChatBadges(): Observable<null> {
        const baseUrl = ['clinic', 'admin', 'chat', 'badge', 'clear'];

        const url = this.router.createUrlTree(baseUrl);

        const parsedUrl = `${environment.apiUrl}/${environment.apiVersion}${url}`;

        return this.httpClient.post<null>(parsedUrl, null, { responseType: 'json' }).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.error instanceof ErrorEvent) {
                    return throwError(() => `Error: ${error.error.message}`);
                }
                const errorApi: IError = error.error.error;
                return throwError(() => errorApi);
            }),
        );
    }

    private connect(): void {
        if (!this.socket) {
            this.socket = new ChatSocket(this.translateService, this.authenticationService);
        }
        if (this.socket.ioSocket.connected) {
            this.socket.disconnect();
        }
        this.socket.connect();
    }
}
