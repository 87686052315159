import { ModuleWithProviders, NgModule } from '@angular/core';
import { KiwidPermissionsDirective } from './directive/kiwid-permissions.directive';
export interface KiwidPermissionsModuleConfig {
    rolesIsolate?: boolean;
    permissionsIsolate?: boolean;
    configurationIsolate?: boolean;
}
@NgModule({
    declarations: [KiwidPermissionsDirective],
    imports: [],
    exports: [KiwidPermissionsDirective],
})
export class KiwidPermissionsModule {
    static forRoot(config: KiwidPermissionsModuleConfig = {}): ModuleWithProviders<KiwidPermissionsModule> {
        return {
            ngModule: KiwidPermissionsModule,
            providers: [],
        };
    }

    static forChild(config: KiwidPermissionsModuleConfig = {}): ModuleWithProviders<KiwidPermissionsModule> {
        return {
            ngModule: KiwidPermissionsModule,
            providers: [],
        };
    }
}
